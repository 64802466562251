import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { products } from '../config/config';
import './Category.css'

export const AllCategory = () => {
    const scrollContainerRef = useRef(null);
    const navigate = useNavigate();

    const goToSubList = (product) => {
        window.scrollTo(0, 0);
      navigate(`/category/${product}`);
    };


    const scrollHorizontally = (scrollOffset) => {
        if (scrollContainerRef.current) {
            console.log("test")
            scrollContainerRef.current.scrollBy({
                left: scrollOffset,
                behavior: 'smooth'
              });
        }
    };

    return <div class="all-category" >
        
        <div className="scrollBox" ref={scrollContainerRef}>
            <div className='arrow-btn' style={{ backgroundImage: `url(../images/left_arrow.svg)` }} onClick={() => { scrollHorizontally(-200) }}>
            </div>
            {products.map(item => {
                return <span className='product-box-ctgry' style={{ backgroundImage: `url(../images/${item.filename})` }}  onClick={()=>{goToSubList(item.name)}}>
                      <span className='product-title' >{item.name}</span>
                </span>
            })}
            <div className='arrow-btn arrow-btn-right' style={{ backgroundImage: `url(../images/right_Arrow.svg)` }} onClick={() => { scrollHorizontally(200) }}></div>
        </div>
    </div>
}