import { Address } from "../Address/Address"
import { isMobileDevice } from "../utils/utils,"

export const Footer = () => {
    return <>
        <Address />
        {
            isMobileDevice() && <a href={`https://wa.me/%2B918883942138?text=${encodeURIComponent('Hi, I need an inquiry from Design Printers')}`} target={'_blank'}>
                <img src={`${process.env.PUBLIC_URL}/images/whatsapp-notify.webp`} className="whatsappFixed" alt="whatsapp" />
            </a>
        }
    </>
}