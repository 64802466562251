import React from 'react';
import {  Routes, Route } from 'react-router-dom';
import App from './App';
import { Category } from './Category/Category';

export const Router = () =>{
    return <Routes>
              <Route exact path="/" element={<App/>}>
              </Route>
              {/* <Route path="/">
                <About />
              </Route> */}
              <Route path="/category/:name" element={<Category/>}>
              </Route>
          </Routes>
}