import './header.css'

export const Header = () => {
    const message = "Hello, I have seen your Design Printers website!";

    const scrollIntoView = ( value)=>{
        const element = document.getElementById(value);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return <div class="header-box">
        <div class="header-logo"></div>
        <ul>
            <li><a href='https://www.designprinters.in'>Home</a></li>
            <li><a href='https://www.designprinters.in#services'>Our Services</a></li>
            <li><a href='https://www.designprinters.in#about'>About Us</a></li>
            <li><a href={`https://wa.me/%2B918883942138?text=${encodeURIComponent(message)}`} target={'_blank'}>
            <img src={`${process.env.PUBLIC_URL}/images/whatsapp.webp`}  className="whatsapp" alt="whatsapp" />
                888 39 421 38</a></li>
        </ul>
    </div>
}