// import phone from 'public/images/phone.png'
import './Address.css'
export const Address = () => {

    return <div className='Address-Box'>
        <div className='Address'>
            <div>
                <img src={`${process.env.PUBLIC_URL}/images/location.png`} alt="location" />
                <span>SND Road, Tiruchengode - 637 211,
                    Namakkal-Dt,TamilNadu</span>
            </div>
            <div>
                <a href="tel:8883942138"><img src={`${process.env.PUBLIC_URL}/images/phone.png`} alt="phone" /></a>
                <span><a href="tel:8883942138">
                    888 39 421 38
                </a></span>
            </div>
        </div>
        <div class="follow">
            <h1>Follow us</h1>
            <div className='socialMedia'>
                <img src={`${process.env.PUBLIC_URL}/images/insta.webp`} className="insta" alt="instagram" />
                <a
                    href="https://www.instagram.com/design_printers/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span >design_printers</span></a>
            </div>
            <div className='socialMedia'>
                <img src={`${process.env.PUBLIC_URL}/images/whatsapp.webp`} className="insta" alt="whatsapp" />
                <a href={`https://wa.me/%2B918883942138?text=${encodeURIComponent('Hi, this message is from design printers site.')}`} target={'_blank'}>
                    <span >88839 42138</span>
                </a>
            </div>
        </div>
    </div>
}