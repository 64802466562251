import { useNavigate } from 'react-router-dom';
import { About } from './About/About';
import { Address } from './Address/Address';
import './App.css';
import { products } from './config/config';
import { Header } from './Header/header';
import { isMobileDevice } from './utils/utils,';
import { Footer } from './Footer/Footer';



function App() {
  const navigate = useNavigate();

  const goToSubList = (product) => {
    navigate(`/category/${product}`);
  };

  return (
    <div className="App">
      {!isMobileDevice() && <Header></Header>}
      <header className="Banner">
        <div className='name' ></div>
        <div className='phrase' ></div>
      </header>
        <h1 className='services' id='services'>Our Services</h1>
        <div className='product-box'>
          {products.map(item => {
            return <div className='product' onClick={()=>{goToSubList(item.name)}} style={{ backgroundImage: `url(../images/${item.filename})`}}>
            <span className='product-title' >{item.name}</span>
          </div>
          })}
        
        </div>
      <About/>
      <Footer/>
    </div>

  );
}

export default App;
