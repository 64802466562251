import { useParams } from "react-router-dom";
import { ProductList } from "../config/config";
import { HeaderMobile } from "../Header/headerMobile";
import { Header } from "../Header/header";
import { AllCategory } from "./AllCategory"
import './Category.css'
import { isMobileDevice } from '../utils/utils,';
import { useEffect, useState } from "react";
import { Footer } from "../Footer/Footer";
export const Category = () => {
  let { name } = useParams();
  const [productList, setProductList] = useState({}); 

  useEffect(() => {
    window.scrollTo(0, 0);
      setProductList(ProductList);
  }, []);

  return <div className="category-box">
    {isMobileDevice() ? <HeaderMobile /> : <Header />}

    <h1 className='sub-list-services'>{name}</h1>
    <div className='sub-list-product-box'>
      {productList?.[name]?.map(item => {
        return <div className='sub-list-product'>
          <a href={`https://wa.me/%2B918883942138?text=${encodeURIComponent('I want to ask about ' + name)}`} target={'_blank'}>
            <div  className='sub-list-product sub-list-product-height' style={{ backgroundImage: `url(../products/${encodeURIComponent(name)}/${encodeURIComponent(item.filename)})` }}>
              {item.name && <span className='sub-list-product-title' >{item.name}</span>}
            </div>
          </a>
          <div className="sub-lis-specifications">
            {item.specifications && Object.entries(item.specifications).map(([key, value]) => {
              return <div><span style={{'font-weight': 'bold'}}>{key + "  :   "}</span>
              <span>{value}</span>
              </div>
            })}
          </div>
        </div>
      })}
    </div>
    <h1 className='sub-list-services'>More Products</h1>
    <AllCategory />
    <Footer/>
  </div>
}