export const ProductList = {
    "Bill Book" :[{
        id: 1,
        name: 'Bill Book',
        filename: 'billbook.jpg'
    },{
        id: 2,
        name: 'Voucher Book',
        filename: 'voucher book.jpg'
    }],
    "All Type of Covers": [{
        id: 1,
        name: 'a4 cover multicolour printing',
        filename: 'a4 cover multicolour printing.jpg'
    },{
        id: 2,
        name: 'envelop window cover',
        filename: 'envelop window cover.jpg'
    },{
        id: 3,
        name: 'multicolour window cover',
        filename: 'multicolour window cover.jpg'
    },{
        id: 4,
        name: 'top open envelop cover',
        filename: 'top open envelop cover.jpg'
    },{
        id: 5,
        name: 'top open single colour cover',
        filename: 'top open single colour cover.jpg'
    },{
        id: 6,
        name: 'top open single colour cover-2',
        filename: 'top open single colour cover-1.jpg'
    }],
    "Flyer":[{
        id: 1,
        name: 'A4 flyer double side',
        filename: 'A4 flyer double side.jpg'
    },{
        id: 2,
        name: 'A4 single side',
        filename: 'A4 single side.jpg'
    },{
        id: 3,
        name: 'A5-Flyer double side',
        filename: 'A5-Flyer double side.jpg'
    },{
        id:4,
        name: 'A5-Flyer single side',
        filename: 'A5-Flyer single side.jpg'
    }],
    "Hospital Files":[{
        id: 1,
        name: '300gsm art board file with multicolour printing',
        filename: '300gsm art board file with multicolour printing'
    },{
        id: 2,
        name: 'synthetic-hospital-files',
        filename: 'synthetic-hospital-files'
    }],
    "Id card - Lanyard": [{
        id: 1,
        filename: 'id card-2.jpg',
    },{
        id: 2,
        filename: 'idcard.png',
    },{
        id: 3,
        filename: 'id card-3.jpg',
    },{
        id: 4,
        filename: 'lanyard multicolour print-1.jpg'
    },{
        id: 5,
        filename: 'lanyard multicolour print-2.jpg'
    },{
        id: 6,
        filename: 'lanyard multicolour print-3.jpg'
    },{
        id: 7,
        filename: 'lanyard multicolour print.jpg'
    }],
    "Vinyl": [{
        id: 1,
        filename: 'glass door  sticker.jpg',
        name: "glass door  sticker"
    },{
        id: 2,
        filename: 'One Vision sticker premium.jpg',
        name: "One Vision sticker premium"
    },{
        id: 3,
        filename: 'One_Way_Vision normal.jpg',
        name: "One Vision normal"
    },{
        id: 4,
        filename: 'OneVision inkjet.jpg',
        name: "One Vision Inkjet"
    },{
        id: 5,
        filename: 'vinyl sticker normal.jpg',
        name: "vinyl sticker normal"
    },{
        id: 6,
        filename: 'vinyl sticker premium.png',
        name: "vinyl sticker premium"
    },{
        id: 7,
        filename: 'vinyl-sticker inkjet.jpg',
        name: "vinyl-sticker inkjet"
    },],
    "Flex": [{
        id: 1,
        filename: 'backlight flex 1.jpeg',
        name: "backlight flex"
    },{
        id: 2,
        filename: 'backlight flex.jpeg',
        name: "backlight flex"
    },{
        id: 3,
        filename: 'star backlight 1.jpeg',
        name: "star backlight"
    },{
        id: 4,
        filename: 'star backlight flex.jpeg',
        name: "star backlight flex"
    },{
        id: 5,
        filename: 'star flex.jpeg',
        name: "Star flex"
    }],
    "Letter Head": [{
        id: 1,
        filename: 'letter head.jpg',
        specifications: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 2,
        filename: 'letter head-1.jpg',
        specifications: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 3,
        filename: 'letter head-2.jpg',
        specifications: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 4,
        filename: 'letter head-3.jpg',
        specifications: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },
    ],
    "Visiting Card":[{
        id: 1,
        filename: 'gloss card.jpg',
        name: "Gloss lamination",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 3,
        filename: 'matt lamintion card.jpg',
        name: "Matt lamination",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 4,
        filename: 'Round-Card.jpg',
        name: "Round corner cut",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 5,
        filename: 'spot uv + 250gsm board.jpg',
        name: "spot UV",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 6,
        filename: 'synthetic non-tearable-1.jpg',
        name: "synthetic (non-tearable)",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    }],
    "Customizable Stickers":[{
        id: 1,
        filename: 'sticker.jpg',
        name: "Sticker",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 2,
        filename: 'die cut sticker-1.jpg',
        name: "die cut sticker",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 3,
        filename: 'medal sticker.jpg',
        name: "medal sticker",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 4,
        filename: 'gold trophy sticker.jpg',
        name: "gold trophy sticker",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 5,
        filename: 'trophy sticker.jpg',
        name: "Trophy sticker",
        specifications1: {
            paper: '100GSM bond sheet',
            colourType: 'single colour',
            size: 'A4',
        },
        button: 'enquiry'
    },{
        id: 6,
        filename: "3D-Metal-Transfer-Stickers.jpg",
        name: "3D-Metal-Transfer-Stickers"
    },{
        id: 7,
        filename: "uv ink transfer sticker.jpg",
        name: "uv ink transfer sticker"
    },{
        id: 8,
        filename: "ink transfer sticker.jpg",
        name: "ink transfer sticker"
    },{
        id: 9,
        filename: "3D dome sticker-1.jpg",
        name: "3D dome sticker"
    },{
        id: 10,
        filename: "transparent sticker.jpg",
        name: "transparent sticker"
    },{
        id: 11,
        filename: "round shape sticker.jpg",
        name: "round shape sticker"
    },{
        id: 12,
        filename: "die cut sticker.jpg",
        name: "die cut sticker"
    },{
        id: 13,
        filename: "packing sticker.jpg",
        name: "packing sticker"
    },{
        id: 14,
        filename: "sticker label.jpg",
        name: "sticker label"
    }],
    "Daily & Monthly Calendar With Customised design":[{
        id: 100,
        filename: 'daily calender.webp',
        name: "daily calender"
    },{
        id: 101,
        filename: 'die cut daily calender .webp',
        name: "die cut daily calender"
    },{
        id: 102,
        filename: 'die cut.webp',
        name: "die cut"
    },{
        id: 103,
        filename: 'Gold foil .jpg',
        name: 'Gold foil'
    },{
        id: 104,
        filename: 'gold foil with frame.jpg',
        name: 'gold foil with frame'
    },{
        id: 105,
        filename: 'gold foil with frame-2.jpeg',
        name: 'gold foil with frame'
    },{
        id: 106,
        filename: 'gold foil.jpg',
        name: "gold foil"
    },{
        id: 107,
        filename: '2in1 calendar.jpeg',
        name: "2in1 calendar"
    },{
        id: 108,
        filename: 'Customised daily calenders.jpg',
        name: "Customised daily calenders"
    },{
        id: 1,
        filename: 'monthly1.jpg'
    },{
        id: 2,
        filename: 'monthly2.jpg'
    },{
        id: 3,
        filename: 'monthly3.jpg'
    },{
        id: 4,
        filename: 'monthly4.jpg'
    },{
        id: 5,
        filename: 'monthly5.jpg'
    },{
        id: 6,
        filename: 'monthly6.jpg'
    },{
        id: 7,
        filename: 'monthly7.jpg'
    }]
}

export const products = [{
    name: 'Visiting Card',
    filename: 'product1.png'
}, {
    name: 'Flyer',
    filename: 'Flyer.jpg'
},{
    name: "Daily & Monthly Calendar With Customised design",
    filename: 'monthly1.jpg'
}, {
    name: 'Letter Head',
    filename: 'letterhead.jpg'
}, {
    name: 'Vinyl',
    filename: 'vinyl-sticker-inkjet.jpg'
}, {
    name: 'Flex',
    filename: 'flex.jpeg'
}, {
    name: 'Customizable Stickers',
    filename: 'sticker.jpg'
}, {
    name: 'All Type of Covers',
    filename: 'Covers.jpeg'
}, {
    name: 'Id card - Lanyard',
    filename: 'Idcard.jpg'
}, {
    name: 'Bill Book',
    filename: 'billbook.jpg'
}]